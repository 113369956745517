import { defineNuxtPlugin } from '#app/nuxt'
import { LazyButtonBackToTop, LazyButtonPrimary, LazyGlobalSkeleton, LazyGlobalBoxCardEvent, LazyGlobalBoxCardProduct, LazyGlobalBoxFAQs, LazyGlobalBoxRecommend, LazyGlobalBreadcrumb, LazyGlobalDateTimePicker, LazyGlobalFooter, LazyGlobalHeader, LazyGlobalHeading, LazyGlobalItemGridNew, LazyGlobalItemListNew, LazyGlobalItemNavFooter, LazyGlobalItemNavMenu, LazyGlobalMegaMenu, LazyGlobalMenuMobile, LazyGlobalNavFooter, LazyGlobalNavMenu, LazyGlobalNavigationSwiper, LazyGlobalPagination, LazyGlobalPopupCopySuccess, LazyGlobalPopupGlobal, LazyGlobalSelect, LazyGlobalVerifyForm, LazyIconsEng, LazyIconsPlayPrimary, LazyIconsVie, LazyIconsZalo, LazyIconsZaloFaqs, LazyMenuItemLeft, LazyMenuItemMenu, LazyMenuItemRight, LazyMenuSubItem, LazyTableOfContentToc, LazyTableOfContentTocList } from '#components'
const lazyGlobalComponents = [
  ["ButtonBackToTop", LazyButtonBackToTop],
["ButtonPrimary", LazyButtonPrimary],
["GlobalSkeleton", LazyGlobalSkeleton],
["GlobalBoxCardEvent", LazyGlobalBoxCardEvent],
["GlobalBoxCardProduct", LazyGlobalBoxCardProduct],
["GlobalBoxFAQs", LazyGlobalBoxFAQs],
["GlobalBoxRecommend", LazyGlobalBoxRecommend],
["GlobalBreadcrumb", LazyGlobalBreadcrumb],
["GlobalDateTimePicker", LazyGlobalDateTimePicker],
["GlobalFooter", LazyGlobalFooter],
["GlobalHeader", LazyGlobalHeader],
["GlobalHeading", LazyGlobalHeading],
["GlobalItemGridNew", LazyGlobalItemGridNew],
["GlobalItemListNew", LazyGlobalItemListNew],
["GlobalItemNavFooter", LazyGlobalItemNavFooter],
["GlobalItemNavMenu", LazyGlobalItemNavMenu],
["GlobalMegaMenu", LazyGlobalMegaMenu],
["GlobalMenuMobile", LazyGlobalMenuMobile],
["GlobalNavFooter", LazyGlobalNavFooter],
["GlobalNavMenu", LazyGlobalNavMenu],
["GlobalNavigationSwiper", LazyGlobalNavigationSwiper],
["GlobalPagination", LazyGlobalPagination],
["GlobalPopupCopySuccess", LazyGlobalPopupCopySuccess],
["GlobalPopupGlobal", LazyGlobalPopupGlobal],
["GlobalSelect", LazyGlobalSelect],
["GlobalVerifyForm", LazyGlobalVerifyForm],
["IconsEng", LazyIconsEng],
["IconsPlayPrimary", LazyIconsPlayPrimary],
["IconsVie", LazyIconsVie],
["IconsZalo", LazyIconsZalo],
["IconsZaloFaqs", LazyIconsZaloFaqs],
["MenuItemLeft", LazyMenuItemLeft],
["MenuItemMenu", LazyMenuItemMenu],
["MenuItemRight", LazyMenuItemRight],
["MenuSubItem", LazyMenuSubItem],
["TableOfContentToc", LazyTableOfContentToc],
["TableOfContentTocList", LazyTableOfContentTocList]
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
