import { default as defaultMMV5qJuxEqMeta } from "/app/pages/default.vue?macro=true";
import { default as aboutkUC1q6gCvzMeta } from "/app/app/views/about?macro=true";
import { default as contact9xppTWObgXMeta } from "/app/app/views/contact?macro=true";
import { default as homew7y6eWECMEMeta } from "/app/app/views/home?macro=true";
import { default as mobile_45apphj7dVBnqxMMeta } from "/app/app/views/mobile-app?macro=true";
import { default as policyhsbjzYqCoWMeta } from "/app/app/views/policy?macro=true";
import { default as dynamic_45pageUkDSiNVTYYMeta } from "/app/app/views/dynamic-page?macro=true";
import { default as searcht3XBQZO6UAMeta } from "/app/app/views/search?macro=true";
import { default as analysisAopmDCpSsMMeta } from "/app/app/views/analysis?macro=true";
import { default as _91slug_93_45_91id_93sdDxbOw3bhMeta } from "/app/app/views/analysis/[slug]-[id]?macro=true";
import { default as faqMy2v4WSRryMeta } from "/app/app/views/faq?macro=true";
import { default as _91slug_93_45_91id_93kgLw1YofqnMeta } from "/app/app/views/faq/[slug]-[id]?macro=true";
import { default as _91id_937P8Tv3iWMPMeta } from "/app/app/views/faq/[slug]/[id]?macro=true";
import { default as newsfQSK3wWEBvMeta } from "/app/app/views/news?macro=true";
import { default as _91slug_93PELkVboBtMMeta } from "/app/app/views/news/[slug]?macro=true";
import { default as _91slug_93UM4AfI2rZtMeta } from "/app/app/views/news/[slug-category]/[slug]?macro=true";
import { default as _91slug_93fJRTSLfk6hMeta } from "/app/app/views/products/[slug]?macro=true";
import { default as products2um9Wmn55oMeta } from "/app/app/views/products?macro=true";
export default [
  {
    name: defaultMMV5qJuxEqMeta?.name ?? "default___en-US",
    path: defaultMMV5qJuxEqMeta?.path ?? "/en-US/default",
    meta: defaultMMV5qJuxEqMeta || {},
    alias: defaultMMV5qJuxEqMeta?.alias || [],
    redirect: defaultMMV5qJuxEqMeta?.redirect || undefined,
    component: () => import("/app/pages/default.vue").then(m => m.default || m)
  },
  {
    name: defaultMMV5qJuxEqMeta?.name ?? "default___vi-VN",
    path: defaultMMV5qJuxEqMeta?.path ?? "/default",
    meta: defaultMMV5qJuxEqMeta || {},
    alias: defaultMMV5qJuxEqMeta?.alias || [],
    redirect: defaultMMV5qJuxEqMeta?.redirect || undefined,
    component: () => import("/app/pages/default.vue").then(m => m.default || m)
  },
  {
    name: aboutkUC1q6gCvzMeta?.name ?? "about___en-US",
    path: aboutkUC1q6gCvzMeta?.path ?? "/en-US/ve-chung-toi",
    alias: ["/en/about"].concat(aboutkUC1q6gCvzMeta?.alias || []),
    meta: aboutkUC1q6gCvzMeta || {},
    redirect: aboutkUC1q6gCvzMeta?.redirect || undefined,
    component: () => import("/app/app/views/about").then(m => m.default || m)
  },
  {
    name: aboutkUC1q6gCvzMeta?.name ?? "about___vi-VN",
    path: aboutkUC1q6gCvzMeta?.path ?? "/ve-chung-toi",
    alias: ["/en/about"].concat(aboutkUC1q6gCvzMeta?.alias || []),
    meta: aboutkUC1q6gCvzMeta || {},
    redirect: aboutkUC1q6gCvzMeta?.redirect || undefined,
    component: () => import("/app/app/views/about").then(m => m.default || m)
  },
  {
    name: contact9xppTWObgXMeta?.name ?? "contact___en-US",
    path: contact9xppTWObgXMeta?.path ?? "/en-US/lien-he",
    alias: ["/en/contact"].concat(contact9xppTWObgXMeta?.alias || []),
    meta: contact9xppTWObgXMeta || {},
    redirect: contact9xppTWObgXMeta?.redirect || undefined,
    component: () => import("/app/app/views/contact").then(m => m.default || m)
  },
  {
    name: contact9xppTWObgXMeta?.name ?? "contact___vi-VN",
    path: contact9xppTWObgXMeta?.path ?? "/lien-he",
    alias: ["/en/contact"].concat(contact9xppTWObgXMeta?.alias || []),
    meta: contact9xppTWObgXMeta || {},
    redirect: contact9xppTWObgXMeta?.redirect || undefined,
    component: () => import("/app/app/views/contact").then(m => m.default || m)
  },
  {
    name: homew7y6eWECMEMeta?.name ?? "home___en-US",
    path: homew7y6eWECMEMeta?.path ?? "/en-US",
    alias: ["/en/"].concat(homew7y6eWECMEMeta?.alias || []),
    meta: homew7y6eWECMEMeta || {},
    redirect: homew7y6eWECMEMeta?.redirect || undefined,
    component: () => import("/app/app/views/home").then(m => m.default || m)
  },
  {
    name: homew7y6eWECMEMeta?.name ?? "home___vi-VN",
    path: homew7y6eWECMEMeta?.path ?? "/",
    alias: ["/en/"].concat(homew7y6eWECMEMeta?.alias || []),
    meta: homew7y6eWECMEMeta || {},
    redirect: homew7y6eWECMEMeta?.redirect || undefined,
    component: () => import("/app/app/views/home").then(m => m.default || m)
  },
  {
    name: mobile_45apphj7dVBnqxMMeta?.name ?? "mobile_app___en-US",
    path: mobile_45apphj7dVBnqxMMeta?.path ?? "/en-US/ung-dung-dien-thoai",
    alias: ["/en/mobile-app"].concat(mobile_45apphj7dVBnqxMMeta?.alias || []),
    meta: mobile_45apphj7dVBnqxMMeta || {},
    redirect: mobile_45apphj7dVBnqxMMeta?.redirect || undefined,
    component: () => import("/app/app/views/mobile-app").then(m => m.default || m)
  },
  {
    name: mobile_45apphj7dVBnqxMMeta?.name ?? "mobile_app___vi-VN",
    path: mobile_45apphj7dVBnqxMMeta?.path ?? "/ung-dung-dien-thoai",
    alias: ["/en/mobile-app"].concat(mobile_45apphj7dVBnqxMMeta?.alias || []),
    meta: mobile_45apphj7dVBnqxMMeta || {},
    redirect: mobile_45apphj7dVBnqxMMeta?.redirect || undefined,
    component: () => import("/app/app/views/mobile-app").then(m => m.default || m)
  },
  {
    name: policyhsbjzYqCoWMeta?.name ?? "policy___en-US",
    path: policyhsbjzYqCoWMeta?.path ?? "/en-US/chinh-sach",
    alias: ["/en/policy"].concat(policyhsbjzYqCoWMeta?.alias || []),
    meta: policyhsbjzYqCoWMeta || {},
    redirect: policyhsbjzYqCoWMeta?.redirect || undefined,
    component: () => import("/app/app/views/policy").then(m => m.default || m)
  },
  {
    name: policyhsbjzYqCoWMeta?.name ?? "policy___vi-VN",
    path: policyhsbjzYqCoWMeta?.path ?? "/chinh-sach",
    alias: ["/en/policy"].concat(policyhsbjzYqCoWMeta?.alias || []),
    meta: policyhsbjzYqCoWMeta || {},
    redirect: policyhsbjzYqCoWMeta?.redirect || undefined,
    component: () => import("/app/app/views/policy").then(m => m.default || m)
  },
  {
    name: dynamic_45pageUkDSiNVTYYMeta?.name ?? "privacy_policy___en-US",
    path: dynamic_45pageUkDSiNVTYYMeta?.path ?? "/en-US/trang/:slug",
    alias: ["/en/en/page/:slug"].concat(dynamic_45pageUkDSiNVTYYMeta?.alias || []),
    meta: dynamic_45pageUkDSiNVTYYMeta || {},
    redirect: dynamic_45pageUkDSiNVTYYMeta?.redirect || undefined,
    component: () => import("/app/app/views/dynamic-page").then(m => m.default || m)
  },
  {
    name: dynamic_45pageUkDSiNVTYYMeta?.name ?? "privacy_policy___vi-VN",
    path: dynamic_45pageUkDSiNVTYYMeta?.path ?? "/trang/:slug",
    alias: ["/en/en/page/:slug"].concat(dynamic_45pageUkDSiNVTYYMeta?.alias || []),
    meta: dynamic_45pageUkDSiNVTYYMeta || {},
    redirect: dynamic_45pageUkDSiNVTYYMeta?.redirect || undefined,
    component: () => import("/app/app/views/dynamic-page").then(m => m.default || m)
  },
  {
    name: searcht3XBQZO6UAMeta?.name ?? "result___en-US",
    path: searcht3XBQZO6UAMeta?.path ?? "/en-US/tim-kiem",
    alias: ["/en/search"].concat(searcht3XBQZO6UAMeta?.alias || []),
    meta: searcht3XBQZO6UAMeta || {},
    redirect: searcht3XBQZO6UAMeta?.redirect || undefined,
    component: () => import("/app/app/views/search").then(m => m.default || m)
  },
  {
    name: searcht3XBQZO6UAMeta?.name ?? "result___vi-VN",
    path: searcht3XBQZO6UAMeta?.path ?? "/tim-kiem",
    alias: ["/en/search"].concat(searcht3XBQZO6UAMeta?.alias || []),
    meta: searcht3XBQZO6UAMeta || {},
    redirect: searcht3XBQZO6UAMeta?.redirect || undefined,
    component: () => import("/app/app/views/search").then(m => m.default || m)
  },
  {
    name: dynamic_45pageUkDSiNVTYYMeta?.name ?? "term_and_condition___en-US",
    path: dynamic_45pageUkDSiNVTYYMeta?.path ?? "/en-US/trang/:slug",
    alias: ["/en/page/:slug"].concat(dynamic_45pageUkDSiNVTYYMeta?.alias || []),
    meta: dynamic_45pageUkDSiNVTYYMeta || {},
    redirect: dynamic_45pageUkDSiNVTYYMeta?.redirect || undefined,
    component: () => import("/app/app/views/dynamic-page").then(m => m.default || m)
  },
  {
    name: dynamic_45pageUkDSiNVTYYMeta?.name ?? "term_and_condition___vi-VN",
    path: dynamic_45pageUkDSiNVTYYMeta?.path ?? "/trang/:slug",
    alias: ["/en/page/:slug"].concat(dynamic_45pageUkDSiNVTYYMeta?.alias || []),
    meta: dynamic_45pageUkDSiNVTYYMeta || {},
    redirect: dynamic_45pageUkDSiNVTYYMeta?.redirect || undefined,
    component: () => import("/app/app/views/dynamic-page").then(m => m.default || m)
  },
  {
    name: analysisAopmDCpSsMMeta?.name ?? "analysis___en-US",
    path: analysisAopmDCpSsMMeta?.path ?? "/en-US/phan-tich",
    alias: ["/en/analysis"].concat(analysisAopmDCpSsMMeta?.alias || []),
    meta: analysisAopmDCpSsMMeta || {},
    redirect: analysisAopmDCpSsMMeta?.redirect || undefined,
    component: () => import("/app/app/views/analysis").then(m => m.default || m)
  },
  {
    name: analysisAopmDCpSsMMeta?.name ?? "analysis___vi-VN",
    path: analysisAopmDCpSsMMeta?.path ?? "/phan-tich",
    alias: ["/en/analysis"].concat(analysisAopmDCpSsMMeta?.alias || []),
    meta: analysisAopmDCpSsMMeta || {},
    redirect: analysisAopmDCpSsMMeta?.redirect || undefined,
    component: () => import("/app/app/views/analysis").then(m => m.default || m)
  },
  {
    name: _91slug_93_45_91id_93sdDxbOw3bhMeta?.name ?? "analysis_slug___en-US",
    path: _91slug_93_45_91id_93sdDxbOw3bhMeta?.path ?? "/en-US/phan-tich/:slug",
    alias: ["/en/analysis/:slug"].concat(_91slug_93_45_91id_93sdDxbOw3bhMeta?.alias || []),
    meta: _91slug_93_45_91id_93sdDxbOw3bhMeta || {},
    redirect: _91slug_93_45_91id_93sdDxbOw3bhMeta?.redirect || undefined,
    component: () => import("/app/app/views/analysis/[slug]-[id]").then(m => m.default || m)
  },
  {
    name: _91slug_93_45_91id_93sdDxbOw3bhMeta?.name ?? "analysis_slug___vi-VN",
    path: _91slug_93_45_91id_93sdDxbOw3bhMeta?.path ?? "/phan-tich/:slug",
    alias: ["/en/analysis/:slug"].concat(_91slug_93_45_91id_93sdDxbOw3bhMeta?.alias || []),
    meta: _91slug_93_45_91id_93sdDxbOw3bhMeta || {},
    redirect: _91slug_93_45_91id_93sdDxbOw3bhMeta?.redirect || undefined,
    component: () => import("/app/app/views/analysis/[slug]-[id]").then(m => m.default || m)
  },
  {
    name: faqMy2v4WSRryMeta?.name ?? "faq___en-US",
    path: faqMy2v4WSRryMeta?.path ?? "/en-US/cau-hoi-thuong-gap",
    alias: ["/en/faqs"].concat(faqMy2v4WSRryMeta?.alias || []),
    meta: faqMy2v4WSRryMeta || {},
    redirect: faqMy2v4WSRryMeta?.redirect || undefined,
    component: () => import("/app/app/views/faq").then(m => m.default || m)
  },
  {
    name: faqMy2v4WSRryMeta?.name ?? "faq___vi-VN",
    path: faqMy2v4WSRryMeta?.path ?? "/cau-hoi-thuong-gap",
    alias: ["/en/faqs"].concat(faqMy2v4WSRryMeta?.alias || []),
    meta: faqMy2v4WSRryMeta || {},
    redirect: faqMy2v4WSRryMeta?.redirect || undefined,
    component: () => import("/app/app/views/faq").then(m => m.default || m)
  },
  {
    name: _91slug_93_45_91id_93kgLw1YofqnMeta?.name ?? "faq_category___en-US",
    path: _91slug_93_45_91id_93kgLw1YofqnMeta?.path ?? "/en-US/cau-hoi-thuong-gap/:slug-:id(\\d+)",
    alias: ["/en/faq/:slug-:id(\\d+)"].concat(_91slug_93_45_91id_93kgLw1YofqnMeta?.alias || []),
    meta: _91slug_93_45_91id_93kgLw1YofqnMeta || {},
    redirect: _91slug_93_45_91id_93kgLw1YofqnMeta?.redirect || undefined,
    component: () => import("/app/app/views/faq/[slug]-[id]").then(m => m.default || m)
  },
  {
    name: _91slug_93_45_91id_93kgLw1YofqnMeta?.name ?? "faq_category___vi-VN",
    path: _91slug_93_45_91id_93kgLw1YofqnMeta?.path ?? "/cau-hoi-thuong-gap/:slug-:id(\\d+)",
    alias: ["/en/faq/:slug-:id(\\d+)"].concat(_91slug_93_45_91id_93kgLw1YofqnMeta?.alias || []),
    meta: _91slug_93_45_91id_93kgLw1YofqnMeta || {},
    redirect: _91slug_93_45_91id_93kgLw1YofqnMeta?.redirect || undefined,
    component: () => import("/app/app/views/faq/[slug]-[id]").then(m => m.default || m)
  },
  {
    name: _91id_937P8Tv3iWMPMeta?.name ?? "faq_detail___en-US",
    path: _91id_937P8Tv3iWMPMeta?.path ?? "/en-US/cau-hoi-thuong-gap/:slug/:id(\\d+)",
    alias: ["/en/faq/:slug/:id(\\d+)"].concat(_91id_937P8Tv3iWMPMeta?.alias || []),
    meta: _91id_937P8Tv3iWMPMeta || {},
    redirect: _91id_937P8Tv3iWMPMeta?.redirect || undefined,
    component: () => import("/app/app/views/faq/[slug]/[id]").then(m => m.default || m)
  },
  {
    name: _91id_937P8Tv3iWMPMeta?.name ?? "faq_detail___vi-VN",
    path: _91id_937P8Tv3iWMPMeta?.path ?? "/cau-hoi-thuong-gap/:slug/:id(\\d+)",
    alias: ["/en/faq/:slug/:id(\\d+)"].concat(_91id_937P8Tv3iWMPMeta?.alias || []),
    meta: _91id_937P8Tv3iWMPMeta || {},
    redirect: _91id_937P8Tv3iWMPMeta?.redirect || undefined,
    component: () => import("/app/app/views/faq/[slug]/[id]").then(m => m.default || m)
  },
  {
    name: newsfQSK3wWEBvMeta?.name ?? "news___en-US",
    path: newsfQSK3wWEBvMeta?.path ?? "/en-US/tin-tuc",
    alias: ["/en/news"].concat(newsfQSK3wWEBvMeta?.alias || []),
    meta: newsfQSK3wWEBvMeta || {},
    redirect: newsfQSK3wWEBvMeta?.redirect || undefined,
    component: () => import("/app/app/views/news").then(m => m.default || m)
  },
  {
    name: newsfQSK3wWEBvMeta?.name ?? "news___vi-VN",
    path: newsfQSK3wWEBvMeta?.path ?? "/tin-tuc",
    alias: ["/en/news"].concat(newsfQSK3wWEBvMeta?.alias || []),
    meta: newsfQSK3wWEBvMeta || {},
    redirect: newsfQSK3wWEBvMeta?.redirect || undefined,
    component: () => import("/app/app/views/news").then(m => m.default || m)
  },
  {
    name: _91slug_93PELkVboBtMMeta?.name ?? "news_category___en-US",
    path: _91slug_93PELkVboBtMMeta?.path ?? "/en-US/tin-tuc/:slug",
    alias: ["/en/news/:slug"].concat(_91slug_93PELkVboBtMMeta?.alias || []),
    meta: _91slug_93PELkVboBtMMeta || {},
    redirect: _91slug_93PELkVboBtMMeta?.redirect || undefined,
    component: () => import("/app/app/views/news/[slug]").then(m => m.default || m)
  },
  {
    name: _91slug_93PELkVboBtMMeta?.name ?? "news_category___vi-VN",
    path: _91slug_93PELkVboBtMMeta?.path ?? "/tin-tuc/:slug",
    alias: ["/en/news/:slug"].concat(_91slug_93PELkVboBtMMeta?.alias || []),
    meta: _91slug_93PELkVboBtMMeta || {},
    redirect: _91slug_93PELkVboBtMMeta?.redirect || undefined,
    component: () => import("/app/app/views/news/[slug]").then(m => m.default || m)
  },
  {
    name: _91slug_93UM4AfI2rZtMeta?.name ?? "news_detail___en-US",
    path: _91slug_93UM4AfI2rZtMeta?.path ?? "/en-US/tin-tuc/:category/:slug",
    alias: ["/en/news/:category/:slug"].concat(_91slug_93UM4AfI2rZtMeta?.alias || []),
    meta: _91slug_93UM4AfI2rZtMeta || {},
    redirect: _91slug_93UM4AfI2rZtMeta?.redirect || undefined,
    component: () => import("/app/app/views/news/[slug-category]/[slug]").then(m => m.default || m)
  },
  {
    name: _91slug_93UM4AfI2rZtMeta?.name ?? "news_detail___vi-VN",
    path: _91slug_93UM4AfI2rZtMeta?.path ?? "/tin-tuc/:category/:slug",
    alias: ["/en/news/:category/:slug"].concat(_91slug_93UM4AfI2rZtMeta?.alias || []),
    meta: _91slug_93UM4AfI2rZtMeta || {},
    redirect: _91slug_93UM4AfI2rZtMeta?.redirect || undefined,
    component: () => import("/app/app/views/news/[slug-category]/[slug]").then(m => m.default || m)
  },
  {
    name: _91slug_93fJRTSLfk6hMeta?.name ?? "product_detail___en-US",
    path: _91slug_93fJRTSLfk6hMeta?.path ?? "/en-US/san-pham/:slug",
    alias: ["/en/products/:slug"].concat(_91slug_93fJRTSLfk6hMeta?.alias || []),
    meta: _91slug_93fJRTSLfk6hMeta || {},
    redirect: _91slug_93fJRTSLfk6hMeta?.redirect || undefined,
    component: () => import("/app/app/views/products/[slug]").then(m => m.default || m)
  },
  {
    name: _91slug_93fJRTSLfk6hMeta?.name ?? "product_detail___vi-VN",
    path: _91slug_93fJRTSLfk6hMeta?.path ?? "/san-pham/:slug",
    alias: ["/en/products/:slug"].concat(_91slug_93fJRTSLfk6hMeta?.alias || []),
    meta: _91slug_93fJRTSLfk6hMeta || {},
    redirect: _91slug_93fJRTSLfk6hMeta?.redirect || undefined,
    component: () => import("/app/app/views/products/[slug]").then(m => m.default || m)
  },
  {
    name: products2um9Wmn55oMeta?.name ?? "products___en-US",
    path: products2um9Wmn55oMeta?.path ?? "/en-US/san-pham",
    alias: ["/en/products"].concat(products2um9Wmn55oMeta?.alias || []),
    meta: products2um9Wmn55oMeta || {},
    redirect: products2um9Wmn55oMeta?.redirect || undefined,
    component: () => import("/app/app/views/products").then(m => m.default || m)
  },
  {
    name: products2um9Wmn55oMeta?.name ?? "products___vi-VN",
    path: products2um9Wmn55oMeta?.path ?? "/san-pham",
    alias: ["/en/products"].concat(products2um9Wmn55oMeta?.alias || []),
    meta: products2um9Wmn55oMeta || {},
    redirect: products2um9Wmn55oMeta?.redirect || undefined,
    component: () => import("/app/app/views/products").then(m => m.default || m)
  }
]