{
    "DOWNLOAD_IZI24": "TẢI IZIon24",
    "FAQ_INDEX": "FAQs",
    "FAQ_INDEX_URL": "/cau-hoi-thuong-gap",
    "ABOUT_INDEX_URL": "/ve-chung-toi",
    "POLICY_URL": "/chinh-sach",
    "PRODUCT_URL": "/san-pham",
    "CONTACT_URL": "/lien-he",

    "SHOW_MORE": "Xem thêm",
    "VIEW_ALL": "xem tất cả",
    "SHOW_MORE_URL_NEWS": "tin-tuc",
    "LATEST_NEWS": "Tin tức mới nhất",
    "READ_A_LOT_IN_THE_LAST_24H": "ĐỌC NHIỀU TRONG 24H QUA",
    "FIND_THE_RIGHT_QUESTION": "Nội dung cần tìm ...",
    "LATEST": "Mới nhất",
    "TABLE_OF_CONTENTS": "Mục Lục",
    "RELATED_NEWS": "Tin tức liên quan",
    "MOST_VIEWED_ARTICLES": "Các bài viết được xem nhiều",
    "DOWNLOAD": "Tải xuống",
    "DOWNLOAD2": "Tải",
    "REGISTER_NOW": "Đăng kí ngay",
    "RECRUITMENT": "Tìm vị trí",
    "ENTER_YOUR_EMAIL": "Nhập email của bạn...",
    "BY_REGISTERING": "Qua việc đăng ký, bạn đã đồng ý với ",
    "TERMS_AND_CONDITIONS": "Điều khoản & Điều kiện",
    "CONTENT_YOU_WANT_TO_RECEIVE": "Nội dung bạn muốn nhận",
    "HEALTH": "Sức khoẻ",
    "FINANCIAL": "Tài chính",
    "INSURANCE": "Bảo hiểm",
    "PROMOTION": "Khuyến mãi",
    "PLEASE_ENTER_A_VALID_EMAIL_ADDRESS": "Vui lòng nhập địa chỉ email hợp lệ",
    "SUCCESSFULLY_SENT": "Đã gửi thành công",
    "SUCCESS": "thành công",
    "EMAIL_IS_ALREADY_IN_USE": "Email đã được sử dụng. Chúng tôi sẽ cập nhật lựa chọn của bạn.",

    "SEARCH": "Tìm kiếm",
    "SEARCH_RESULTS": "Kết quả tìm kiếm",
    "FIND": "Tìm thấy",
    "NO_RESULTS_WERE_FOUND": "Rất tiếc! Chúng tôi không tìm thấy kết quả nào phù hợp với tìm kiếm của bạn.",
    "PRODUCT": "Sản phẩm",
    "NEWS": "Tin tức",
    "URL_PRODUCT": "san-pham",
    "URL_ROUTER_NEWS": "/tin-tuc",
    "URL_NEWS": "tin-tuc",

    "INSURANCE_FEES": "Phí bảo hiểm",

    "TOTAL_FEES": "Số tiền bảo hiểm",
    "INSURANCE_COMPANY": "Công ty bảo hiểm",
    "TIME": "Thời gian",
    "TIME_INSURANCE": "Thời hạn bảo hiểm",
    "SHARE_NOW": "Chia sẻ ngay",
    "BENEFITS": "Quyền lợi",
    "LABEL_BENEFIT": "Quyền lợi",
    "LABEL_PREMIUM": "Phí bảo hiểm",

    "DO_YOU_WANT_TO_CONTINUE": "Bạn có muốn làm tiếp không ?",
    "CONTINUE": "Tiếp tục",
    "RESTART": "Bắt đầu lại",
    "NOT_ENOUGH_INFORMATION": "Vui lòng nhập đủ thông tin",
    "ROUTER_ANALYSIS": "/phan-tich",
    "MONTHS": "tháng",
    "YEARS": "năm",

    "HOME": "Trang chủ",
    "ROUTER_TERM_AND_CONDITION": "/trang/dieu-khoan-and-dieu-kien",
    "ROUTER_TERM_OF_USE": "/trang/dieu-khoan-su-dung-agent-app",
    "LEARN_MORE": "Tìm hiểu thêm",
    "DISCOVER_NOW": "khám phá ngay",
    "FIRST_AND_LAST_NAME": "HỌ VÀ TÊN*",
    "PHONE_NUMBER": "Số điện thoại*",
    "PHONE_NUMBER2": "Số điện thoại",
    "PASSWORD": "Mật khẩu",
    "ENTER_YOUR_PHONE_NUMBER": "Nhập số điện thoại...",
    "ENTER_YOUR_FIRST_AND_LAST_NAME": "Nhập họ và tên...",
    "ENTER_YOUR_FIRST_AND_LAST_NAME_FAQ": "HỌ VÀ TÊN",
    "ENTER_YOUR_EMAIL_FAQ": "EMAIL",
    "ENTER_YOUR_PASSWORD": "Nhập mật khẩu...",
    "LET_IS_GET_STARTED": "Bắt đầu nào",
    "CHECK_NOW": "Kiểm tra ngay",
    "DOWNLOAD_IZION24": "tải izion24",
    "YOUR_IDENTITY": "CCCD của bạn",
    "ENTER_IDENTITY": "Nhập CCCD...",
    "I_AGREE_TO_THE": "Tôi đồng ý với",
    "AND": "và",
    "CONFIRM_NOW": "xác nhận ngay",
    "USER_HAS_NO_CURRENT_POLICY": "Người dùng không có chính sách hiện tại",
    "DESCRIPTION_POPUP_RESULT_POLICY": "Bạn chưa được bảo vệ bởi gói bảo hiểm nào, Tham khảo sản phẩm những sản phẩm bảo hiểm của IZIon24 ngay!",
    "PRODUCT_REFERENCE": "tham khảo sản phẩm",
    "ROUTER_PRODUCT": "/san-pham",
    "RESIDENCY": "Tái túc",
    "DISCOVER_INSURANCE_POLICY": "Ứng dụng IZIon 24",
    "CONTENT_DISCOVER_INSURANCE_POLICY": "Ứng dụng bảo hiểm IZIon24 sẽ đơn giản hoá mọi thông tin và mang đến sản phẩm phù hợp nhất.",
    "CHECK_CONTRACT": "Kiểm tra thông tin hợp đồng",
    "NOT_SURE_START": "Khám phá nhu cầu bảo hiểm",
    "CONTACT_NOW": "Liên hệ ngay",
    "MORE_FILTERS": "Thêm bộ lọc khác",
    "HIDE_MORE_FILTERS": "Ẩn bộ lọc khác",
    "COMPANY": "Công ty",
    "TYPE_OF_INSURANCE": "Loại bảo hiểm",
    "INSURANCE_DIRECTORY": "Danh mục bảo hiểm",
    "SUBJECTS_OF_APPLICATION": "Đối tượng áp dụng",
    "UTILITIES": "Tiện ích",
    "INSURANCE_BENEFITS": "Quyền lợi bảo hiểm",
    "SUPPORT_THROUGH": "Hỗ trợ qua",
    "CALL": "Gọi",
    "ROUTER_MOBILE_APP": "/ung-dung-dien-thoai",
    "WHAT_DO_CUSTOMERS_SAY": "Khách hàng nói gì?",
    "NO_DATA": "Nội dung bài viết đang được cập nhật",
    "NO_DATA_PRODUCT": "Thông tin sản phẩm đang được cập nhật",
    "ELIGIBLE_PERSON": "Người đủ điều kiện",
    "EXCEPTION_PERSON": "Độ tuổi ngoại trừ",
    "SEE_RECOMMENDED_PRODUCTS": "Xem các sản phẩm đề xuất",
    "BACK": "Trở về",
    "NEXT": "Tiếp tục",
    "REQUEST_CALL_BACK": "Yêu cầu Gọi lại",
    "CALL_US_NOW": "Gọi cho chúng tôi ngay",
    "APPLY": "Đăng ký",
    "SEE_MORE": "XEM THÊM",
    "DOWNLOAD_FEE_SCHEDULE": "Tải Bảng biểu phí / Brochure",
    "DOWNLOAD_RULES_AND_TERMS": "Tải Quy tắc & điều khoản",
    "SEE_COMPENSATION_GUIDE": "Xem hướng dẫn bồi thường",
    "VIEW_DOCUMENT": "XEM TÀI LIỆU",
    "NO_DOCUMENT": "Nội dung tài liệu đang được cập nhật",
    "SEE_FAQS": "XEM FAQs",
    "FIELD_NAME": "HỌ VÀ TÊN",
    "INPUT_NAME": "Nhập họ và tên...",
    "FIELD_PHONE": "SỐ ĐIỆN THOẠI",
    "INPUT_PHONE": "Nhập số điện thoại...",
    "FIELD_EMAIL": "EMAIL",
    "INPUT_EMAIL": "Nhập Email...",
    "FIELD_PRODUCT": "Loại sản phẩm",
    "INPUT_PRODUCT": "Loại sản phẩm",
    "FIELD_PRODUCT2": "Tìm kiếm sản phẩm",
    "INPUT_PRODUCT2": "Bảo hiểm xe cộ",
    "CONTACT_TO_ME": "GỌI CHO TÔI",
    "OR_TEXT": "HOẶC",
    "CAR_INSURANCE": "Bảo hiểm xe cộ",
    "TIME_TO_CONTACT": "Thời gian để gọi",
    "SUBMIT": "Gửi ngay",
    "SUBMIT_FEEDBACK": "Gửi góp ý ",
    "CONTENT_FEEDBACK_SUCCESS": "Cảm ơn bạn đã đóng góp ý kiến cho IZion 24. Chúng tôi đã tiếp nhận thông tin đánh giá và sẽ tiếp tục cải thiện tốt hơn.",
    "ROUTER_ANALYSIS_START": "/phan-tich/bat-dau",
    "INSURANCE_PRODUCTS": "Sản phẩm bảo hiểm",
    "NEED": "Yêu cầu",
    "CONSULTANE": "tư vấn",
    "INSURANCE_PRODUCTS2": "Các sản phẩm bảo hiểm",
    "ROUTER_POLICY": "/chinh-sach",
    "POLICY": "Chính sách",
    "TERMS": "Điều khoản",
    "PLEASE_ENTER_A_VALID_EMAIL": "Vui lòng nhập email hợp lệ",
    "WE_HAVE_SENT_THE_OTP_TO_THE_EMAIL": "Chúng tôi đã gửi mã OPT vào email:",
    "THE_CODE_WILL_EXPIRE_AFTER": "Mã sẽ hết hiệu lực sau:",
    "CONFIRM": "Xác nhận",
    "PLEASE_ENTER_YOUR_NAME": "Vui lòng nhập tên của bạn",
    "PLEASE_ENTER_YOUR_PHONE_NUMBER": "Vui lòng nhập số điện thoại của bạn",
    "PLEASE_ENTER_YOUR_EMAIL": "Vui lòng nhập email của bạn",
    "PASSWORD_MUST_BE_AT_LEAST_8_CHARACTERS": "Mật khẩu phải có ít nhất 8 kí tự ",
    "IZION24_COSULTING": "Đội ngũ tư vấn của IZIon24 sẽ gọi lại cho bạn trong thời gian sớm nhất! Hãy để ý cuộc gọi với thông tin người gọi là IZIon24 nhé",
    "WORKING_TIME": "Thời gian làm việc",
    "CONTACT": "Liên hệ",
    "SOCIAL_NETWORK": "Mạng xã hội",
    "GENDER": "Giới tính",
    "MR": "Nam",
    "MS": "Nữ",
    "CONSULTING_NEEDS": "Nhu cầu tư vấn*",
    "ADVICE_ON_CHOOSING": "Tư vấn chọn loại bảo hiểm",
    "LEAVE_MESSAGE": "Để lại lời nhắn*",
    "ENTER_MESSENGE": "Nhập lời nhắn...",
    "FIND_THE_RIGHT_QUESTION_2": "Tìm câu hỏi phù hợp...",
    "AGE_FEEDBACK": "Tuổi",
    "JOB_FEEDBACK": "Nghề nghiệp",
    "PRODUCT_FEEDBACK": "Sản phẩm sử dụng",
    "EXAMPLE_FEEDBACK": "VÍ DỤ MINH HOẠ",
    "DOWNLOAD_E_BOOK": "Tải e-book",
    "CUSTOMER_CARE": "Chăm sóc khách hàng",
    "BUSINESS_COOPERATION": "Hợp tác doanh nghiệp",
    "COOKIE_OPTIONS": "Tùy chọn cookie",
    "THE_CONTENT_IS_BEING_UPDATED": "Nội dung đang được cập nhật",
    "INCORRECT_PHONE_NUMBER": "Số điện thoại chưa chính xác",
    "INVALID_ID_NUMBER": "Số CCCD chưa hợp lệ ",
    "AN_ERROR_OCCURRED_WHILE_SENDING_OTP_REQUEST": "Đã xảy ra lỗi trong lúc gửi yêu cầu OTP",
    "LOOKS_LIKE_YOU_MOVING_TOO_FAST": "Có vẻ bạn thao tác quá nhanh. Hãy đợi 2 phút và gửi lại yêu cầu cho chúng tôi",
    "WE_HAVE_SENT_THE_OTP_TO_THE_PHONE_NUMBER": "Chúng tôi đã gửi mã OTP đến số điện thoại :",
    "GET_OTP": "Nhận mã OTP",
    "YEAR": "năm",
    "MONTH": "tháng",
    "DAY": "ngày",
    "YOU_ARE_PROTECTED_WITH": "Bạn đang được bảo vệ với",
    "THE_OTP_CODE_HAS_EXPIRED": "Mã OTP đã hết hạn",
    "PLEASE_RESUBMIT_YOUR_REQUEST_TO_GET_A_NEW_OTP": "Bạn vui lòng gửi lại yêu cầu để nhận mã OTP mới",

    "MAIN_CONTENT": "Nội dung chính",
    "WAS_THIS_ARTICLE_HELPFUL": "Bài viết này không hữu ích?",
    "FEEDBACK_NOW": "Góp ý ngay",
    "PREV_PAGE": "Trang trước",
    "NEXT_PAGE": "Trang sau",
    "EXPIRED_TIME": "Thời gian hết hạn",
    "PRODUCT_VIEW": "xem sản phẩm",
    "YOUR_CONTRACT_IS_STILL": "Hợp đồng của bạn còn ",
    "IT_EXPIRED": "là hết hạn rồi",
    "REGISTERED_IN_THE_SYSTEM": "đã được đăng kí",
    "PLEASE_USE_ANOTHER_PHONE_NUMBER_TO_REGISTER_AN_ACCOUNT": "Vui lòng sử dụng số điện thoại khác để đăng kí tài khoản",
    "OTP_IS_NOT_CORRECT": "OTP chưa chính xác",
    "PLEASE_DOUBLE_CHECK_OTP": "Vui lòng kiểm tra lại OTP",
    "RECRUITMENT1": "Ứng tuyển ",
    "NOMINEE": "Vị trí ứng tuyển*",
    "NOMINEE2": "Vị trí ứng tuyển...",
    "PLEASE_ENTER_THE_POSITION_YOU_ARE_APPLYING_FOR": "Vui lòng nhập vị trí ứng tuyển",
    "THE_DAY_YOU_CAN_GO_TO_WORK": "Ngày có thể đi làm*",
    "PLEASE_SELECT_THE_DATE_YOU_CAN_GO_TO_WORK": "Vui lòng chọn ngày bạn có thể đi làm",
    "DATE_OF_GOING_TO_WORK_MUST_BE_AFTER_THE_CURRENT_DATE": "Ngày đi làm phải sau ngày hiện tại",
    "YOUR_CV": "Đường dẫn CV của bạn...",
    "PLEASE_ENTER_YOUR_CV_LINK": "Vui lòng nhập đường dẫn CV của bạn",
    "NOT_HELPFUL": "Không hữu ích",
    "NORMAL": "Bình thường",
    "VERY_HELPFUL": "Rất hữu ích",
    "IMPORT_CONTENT": "Nhập nội dung...",
    "THIS_ARTICLE": "Bài viết này",
    "IS_USEFUL": " có hữu tích",
    "TO_YOU": "không?",
    "YOUR_REVIEW_WILL_HELP_US_IMPROVE_THE_QUALITY_OF_FUTURE_ARTICLES": "Đánh giá của bạn sẽ giúp chúng tôi cải thiện chất lượng bài viết trong tương lai",
    "PRESS_COOPERATION": "Báo chí / Hợp tác",
    "APPLICATION_SUPPORT": "Hỗ trợ ứng dụng",
    "SERVICE_REFLECTION": "Phản ánh dịch vụ",
    "COMMUNICATION_COLLABORATION": "Truyền thông/Hợp tác",
    "ORTHER": "Khác",
    "THE_SERVER_IS_HAVING_PROBLEMS": "Máy chủ đang gặp sự cố ",
    "SORRY_WE_ARE_EXPERIENCING_SOME_TECHNICAL": "Xin lỗi, chúng tôi đang gặp một số vấn đề kỹ thuật ảnh hưởng đến việc xử lý đăng kí của bạn. Chúng tôi đang làm việc để khắc phục vấn đề này",
    "SORRY_WE_ARE_EXPERIENCING_SOME_TECHNICAL2": "Xin lỗi, chúng tôi đang gặp một số vấn đề kỹ thuật ảnh hưởng đến việc xử lý thông tin của bạn. Chúng tôi đang làm việc để khắc phục vấn đề này",
    "THE_OTP_YOU_ENTERED_IS_NOT_CORRECT": "OTP bạn nhập chưa chính xác",
    "PLEASE_DOUBLE_CHECK_THE_RECEIVED_OTP": "Vui lòng kiểm tra lại OTP đã nhận",
    "THERE_WAS_A_PROBLEM_MAKING_THE_REQUEST": "Đã xảy ra sự cố khi thực hiện yêu cầu",
    "SORRY_WE_WILL_FIX_THE_PROBLEM_AS_SOON_AS_POSSIBLE_WISH_YOU_SYMPATHIZE": "Xin lỗi, chúng tôi sẽ khắc phục sự cố sớm nhất có thể. Mong bạn thông cảm.",
    "DISCOVER_OTHER_PRODUCTS": "khám phá sản phẩm khác",
    "NOTIFICATION": "Thông báo",
    "INSURANCE_PERIOD_FROM": "Thời hạn bảo hiểm từ",
    "TO": "đến",
    "YOUR_POLICY_WILL_EXPIRE_IN_THE_NEXT": "Hợp đồng của quý khách sẽ hết hạn trong",
    "PLEASE_ENSURE_YOUR_RENEWAL": "tới, quý khách vui lòng đảm bảo việc tái tục bảo hiểm được diễn ra đúng hạn để duy trì hiệu lực của hợp đồng bảo hiểm.",
    "OVERDATE_CONTRACT": "Hợp đồng quá hạn",
    "YOUR_CONTRACT_HAS_EXPIRED_FOR_RENEWAL": "Hợp đồng Quý khách đã quá hạn tái tục, để tiếp tục sử dụng gói bảo hiểm, Quý khách vui lòng mua mới sản phẩm.",
    "NEW_PURCHASE": "Mua mới",
    "EXPIRATION_DATE": "Ngày hết hạn",
    "INSURANCE_MONEY": "Số tiền bảo hiểm",
    "INSURANCE_PERIOD": "Thời gian bảo hiểm",
    "PLEASE_FILL_IN_THE_REQUIRED_INFORMATION": "Vui lòng điền những thông tin bắt buộc bên dưới để hệ thống có thể kiêm tra chính xác cho bạn",
    "CHECK": "Kiểm tra",
    "CONTRACT_INFORMATION": "thông tin hợp đồng",
    "REQUEST": "Yêu cầu",
    "RECEIVED": "đã nhận",
    "IZION24_CONSULTING_TEAM_WILL_CALL_YOU_BACK": "Đội ngũ tư vấn của IZIon24 sẽ gọi lại cho bạn trong thời gian sớm nhất!",
    "ROUTER_PROMOTION": "/tin-tuc/khuyen-mai",
    "ROUTER_PRIVARY_POLICY": "/trang/chinh-sach-bao-mat",
    "ABOUT_US": "Về chúng tôi",
    "PLACEHOLDER_SEARCH": "Tìm câu hỏi phù hợp",
    "WHERE_ARE_YOU_FROM": "Bạn đến từ đâu...",
    "PAYMENT": "Khoản chi trả",
    "TYPE_DATEPICKER": "tt/nn/nnnn",
    "SELECT_DATEPICKER": "Chọn",
    "CANCEL_DATEPICKER": "Huỷ",
    "PREMIUM_DES": "Giá trị bảo vệ * 1.65% ( Đã bao gồm 10% thuế VAT)",
    "SUM_INSURE_DES": "Theo bảng giá trị xe quy định bởi PVI",
    "FIELD_ADDRESS": "Nhập địa chỉ",
    "PRIVACY_POLICY_DETAILS": "Chi tiết chính sách bảo mật",
    "ENTER_URL": "Nhập url",
    "TEXT_SITUATION": "Tình huống",
    "APPLY_FILTER": "Áp dụng",
    "TEXT_SEARCH_FILTER": "Chọn tính chất bảo hiểm",
    "INTERESTED_PRODUCT": "Sản phẩm quan tâm",
    "CLOSE": "Đóng",
    "CLICK_TO_UPLOAD": "Click để tải lên",
    "YOUR_CV1": "CV của bạn",
    "PLEASE_ENTER_THE_CORRECT_CV_LINK": "Vui lòng nhập đường dẫn CV chính xác",
    "SELECTED_PRODUCT": "Đã chọn",
    "UNSELECTED_PRODUCT": "Bỏ chọn",
    "SELECTION_PRODUCT_LIST": "Danh sách lựa chọn",
    "CHOOSE_ALL_PRODUCT": "Chọn hết",
    "USE_YOUR_ACCOUNT_REGISTRATION_INFORMATION": "Sử dụng thông tin đăng ký tài khoản để đăng nhập ứng dụng IZIon24 ngay!",

    "INSURNACE_PRODUCT_INFO": "Sản phẩm bảo hiểm trên IZIon24",
    "SIGN_UP": "Đăng ký​​",
    "SUCCESSFUL": "thành công!",
    "DOWNLOAD_IZION24​": "TẢI IZIon24​​",
    "PLEASE_ENTER_A_MESSAGE": "Vui lòng nhập lời nhắn",
    "SENDING_REQUEST_FAILED": "Gửi yêu cầu không thành công ",
    "ALREADY_INSURANCE": "Đã có bảo hiểm?",
    "NOT_SURE_WHERE": "Không rõ bắt đầu từ đâu ?",
    "ROUTER_HOME": "/",
    "EMAIL_IS_ALREADY_IN_USE1": "Email đã được sử dụng ",
    "TERM_AND_CONDITION": "Điều khoản và điều kiện",
    "EXCLUSION_CLAUSE": "Điều khoản loại trừ",
    "SHARE_LINK": "Đã sao chép liên kết"
}
