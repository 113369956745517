{
    "DOWNLOAD_IZI24": "DOWNLOAD IZIon24",
    "FAQ_INDEX": "FAQs",
    "FAQ_INDEX_URL": "/en/faqs",
    "ABOUT_INDEX_URL": "/en/about",
    "POLICY_URL": "/en/policy",
    "PRODUCT_URL": "/en/products",
    "CONTACT_URL": "/en/contact",
    "SHOW_MORE": "Show more",
    "VIEW_ALL": "View all",
    "RECRUITMENT": "Recruitment",
    "SHOW_MORE_URL_NEWS": "news",
    "LATEST_NEWS": "Latest news",
    "READ_A_LOT_IN_THE_LAST_24H": "READ A LOT IN THE LAST 24H",
    "FIND_THE_RIGHT_QUESTION": "Content to look for...",
    "LATEST": "Latest",
    "TABLE_OF_CONTENTS": "TABLE OF CONTENTS",
    "RELATED_NEWS": "Related news",
    "MOST_VIEWED_ARTICLES": "Most viewed articles",
    "DOWNLOAD": "Download",
    "DOWNLOAD2": "Download",
    "REGISTER_NOW": "Register now",
    "ENTER_YOUR_EMAIL": "Enter your email...",
    "BY_REGISTERING": "By registering, you agree to the ",
    "TERMS_AND_CONDITIONS": "Terms & Conditions",
    "CONTENT_YOU_WANT_TO_RECEIVE": "Content you want to receive",
    "HEALTH": "Health",
    "FINANCIAL": "Financial",
    "INSURANCE": "Insurance",
    "INSURANCE_DIRECTORY": "Insurance directory",
    "SUBJECTS_OF_APPLICATION": "Subjects of application",
    "UTILITIES": "Utilities",
    "INSURANCE_BENEFITS": "Insurance benefits",
    "PROMOTION": "Promotion",
    "PLEASE_ENTER_A_VALID_EMAIL_ADDRESS": "Please enter a valid email address",
    "SUCCESSFULLY_SENT": "Successfully sent",
    "EMAIL_IS_ALREADY_IN_USE": "Email is already in use. We will update your selection.",
    "SEARCH": "Search",
    "SEARCH_RESULTS": "Search results",
    "FIND": "Find",
    "NO_RESULTS_WERE_FOUND": "Sorry! We couldn't find any results matching your search.",
    "PRODUCT": "Product",
    "NEWS": "News",
    "URL_PRODUCT": "product",
    "URL_ROUTER_NEWS": "/en/news",
    "URL_NEWS": "news",
    "INSURANCE_FEES": "Insurance fees",
    "TOTAL_FEES": "Insurance fee ",
    "INSURANCE_COMPANY": "Insurance company",
    "TIME": "Time",
    "TIME_INSURANCE": "Time insurance",
    "SHARE_NOW": "Share now",
    "BENEFITS": "Benefit",
    "LABEL_BENEFIT": "Benefit",
    "LABEL_PREMIUM": "Insurance fees",

    "DO_YOU_WANT_TO_CONTINUE": "Do you want to continue?",
    "CONTINUE": "Continue",
    "RESTART": "Restart",
    "NOT_ENOUGH_INFORMATION": "Please enter enough information",
    "ROUTER_ANALYSIS": "/en/analysis",
    "MONTHS": "month",
    "YEARS": "year",
    "HOME": "Home",
    "ROUTER_TERM_AND_CONDITION": "/en/page/terms-and-condition",
    "ROUTER_TERM_OF_USE": "/en/page/term-of-use",
    "LEARN_MORE": "Learn more",
    "DISCOVER_NOW": "discover now",
    "FIRST_AND_LAST_NAME": "First and last name*",
    "PHONE_NUMBER": "Phone Number*",
    "PHONE_NUMBER2": "Phone number",
    "PASSWORD": "Password",
    "ENTER_YOUR_PHONE_NUMBER": "Enter your phone number...",
    "ENTER_YOUR_FIRST_AND_LAST_NAME": "Enter your first and last name...",
    "ENTER_YOUR_FIRST_AND_LAST_NAME_FAQ": "Enter your name",
    "ENTER_YOUR_EMAIL_FAQ": "Enter your email",
    "ENTER_YOUR_PASSWORD": "Enter your passwword...",
    "LET_IS_GET_STARTED": "let’s get started",
    "CHECK_NOW": "Check now",
    "DOWNLOAD_IZION24": "Download izion24",
    "YOUR_IDENTITY": "Your identity",
    "ENTER_IDENTITY": "Enter identity...",
    "AND": "and",
    "I_AGREE_TO_THE": "I agree to the",
    "CONFIRM_NOW": "CONFIRM NOW",
    "USER_HAS_NO_CURRENT_POLICY": "User has no current policy",
    "DESCRIPTION_POPUP_RESULT_POLICY": "You have not been protected by any insurance plan, Check out IZIon24's insurance products now!",
    "PRODUCT_REFERENCE": "product reference",
    "ROUTER_PRODUCT": "/product",
    "RESIDENCY": "Residency",
    "DISCOVER_INSURANCE_POLICY": "Discover insurance policy",
    "CONTENT_DISCOVER_INSURANCE_POLICY": "IZIon24 insurance application will simplify all information and bring the most suitable product.",
    "CHECK_CONTRACT": "Check contract",
    "NOT_SURE_START": "Not sure where to start",
    "CONTACT_NOW": "Contact now",
    "MORE_FILTERS": "More filters",
    "HIDE_MORE_FILTERS": "Hide More filters",
    "COMPANY": "Company",
    "TYPE_OF_INSURANCE": "Type of Insurance",
    "SUPPORT_THROUGH": "Support through",
    "CALL": "Call",
    "ROUTER_MOBILE_APP": "/en/mobile-app",
    "WHAT_DO_CUSTOMERS_SAY": "What do customers say?",
    "NO_DATA": "The content of the article is being updated",
    "NO_DATA_PRODUCT": "Product information is being updated",
    "ELIGIBLE_PERSON": "Eligible Person",
    "EXCEPTION_PERSON": "Exception Person",
    "SEE_RECOMMENDED_PRODUCTS": "See recommended products",
    "BACK": "Back",
    "NEXT": "Next",
    "REQUEST_CALL_BACK": "Request call back",
    "CALL_US_NOW": "Call us now",
    "APPLY": "APPLY",
    "SEE_MORE": "See more",
    "DOWNLOAD_FEE_SCHEDULE": "Download Fee Schedule / Brochure",
    "DOWNLOAD_RULES_AND_TERMS": "Download Rules & Terms",
    "SEE_COMPENSATION_GUIDE": "See Compensation Guide",
    "VIEW_DOCUMENT": "VIEW DOCUMENT",
    "NO_DOCUMENT": "The content of the document is being updated",
    "SEE_FAQS": "SEE FAQs",
    "FIELD_NAME": "FULL NAME",
    "INPUT_NAME": "Enter your name...",
    "FIELD_PHONE": "PHONE",
    "INPUT_PHONE": "Enter your phone...",
    "FIELD_EMAIL": "EMAIL",
    "INPUT_EMAIL": "Enter your email...",
    "FIELD_PRODUCT": "Product type",
    "INPUT_PRODUCT": "Type",
    "FIELD_PRODUCT2": "Product's name",
    "INPUT_PRODUCT2": "Car insurance",
    "TIME_TO_CONTACT": "TIME TO CONTACT",
    "SUBMIT": "Submit now",
    "SUBMIT_FEEDBACK": "Submit feedback",
    "SUCCESS": "successfully",
    "CONTENT_FEEDBACK_SUCCESS": "Thank you for your comments on IZion 24. We have received the review information and will continue to improve better.",
    "CONTACT_TO_ME": "CONTACT TO ME",
    "OR_TEXT": "OR",
    "CAR_INSURANCE": "Car insurance",
    "ROUTER_ANALYSIS_START": "/en/analysis/begin",
    "INSURANCE_PRODUCTS": "Insurance products",
    "NEED": "Need",
    "CONSULTANE": "consultane",
    "INSURANCE_PRODUCTS2": "Insurance products",
    "ROUTER_POLICY": "/en/policy",
    "POLICY": "Policy",
    "RULES": "Rules",
    "TERMS": "Terms",
    "PLEASE_ENTER_YOUR_NAME": "Please enter your name",
    "PLEASE_ENTER_YOUR_PHONE_NUMBER": "Please enter your phone number",
    "PLEASE_ENTER_A_VALID_EMAIL": "Please enter a valid email",
    "PASSWORD_MUST_BE_AT_LEAST_8_CHARACTERS": "Password must be at least 8 characters",
    "WE_HAVE_SENT_THE_OTP_TO_THE_EMAIL": "We have sent the OTP to the email:",
    "THE_CODE_WILL_EXPIRE_AFTER": "The code will expire after:",
    "CONFIRM": "Confirm",
    "PLEASE_ENTER_YOUR_EMAIL": "Please enter your email",
    "IZION24_COSULTING": "IZIon24's consulting team will call you back as soon as possible! Watch out for the call with the caller information IZIon24",
    "WORKING_TIME": "Working time",
    "CONTACT": "Contact",
    "SOCIAL_NETWORK": "Social network",
    "GENDER": "Gender",
    "MR": "Mister (Mr.)",
    "MS": "Miss (Ms.)",
    "CONSULTING_NEEDS": "Consulting needs*",
    "ADVICE_ON_CHOOSING": "Advice on choosing a type of insurance",
    "LEAVE_MESSAGE": "Leave message*",
    "ENTER_MESSENGE": "Enter message...",
    "FIND_THE_RIGHT_QUESTION_2": "Find the right question...",
    "AGE_FEEDBACK": "Age",
    "JOB_FEEDBACK": "Job",
    "PRODUCT_FEEDBACK": "Products used",
    "DOWNLOAD_E_BOOK": "Download e-book",
    "EXAMPLES_FEEDBACK": "ILLUSTRATION EXAMPLE",
    "CUSTOMER_CARE": "Customer care",
    "BUSINESS_COOPERATION": "Business cooperation",
    "COOKIE_OPTIONS": "Cookie options",
    "THE_CONTENT_IS_BEING_UPDATED": "The content is being updated",
    "INCORRECT_PHONE_NUMBER": "Incorrect phone number",
    "INVALID_ID_NUMBER": "Invalid ID number",
    "AN_ERROR_OCCURRED_WHILE_SENDING_OTP_REQUEST": "An error occurred while sending OTP request",
    "LOOKS_LIKE_YOU_MOVING_TOO_FAST": "Looks like you're moving too fast. Please wait 2 minute and send the request back to us",
    "WE_HAVE_SENT_THE_OTP_TO_THE_PHONE_NUMBER": "We have sent the OTP to the phone number :",
    "GET_OTP": "Get OTP",
    "YEAR": "year",
    "MONTH": "month",
    "DAY": "day",
    "THE_OTP_CODE_HAS_EXPIRED": "The OTP code has expired",
    "PLEASE_RESUBMIT_YOUR_REQUEST_TO_GET_A_NEW_OTP": "Please resubmit your request to get a new OTP",
    "MAIN_CONTENT": "Main content",
    "YOU_ARE_PROTECTED_WITH": "You are protected with",
    "WAS_THIS_ARTICLE_HELPFUL": "Was this article helpful?",
    "FEEDBACK_NOW": "Feedback now",
    "PREV_PAGE": "Previous page",
    "NEXT_PAGE": "Next page",
    "EXPIRED_TIME": "Expired time",
    "PRODUCT_VIEW": "product view",
    "YOUR_CONTRACT_IS_STILL": "Your contract is still",
    "IT_EXPIRED": "it's expired",
    "REGISTERED_IN_THE_SYSTEM": "registered",
    "PLEASE_USE_ANOTHER_PHONE_NUMBER_TO_REGISTER_AN_ACCOUNT": "Please use another phone number to register an account",
    "OTP_IS_NOT_CORRECT": "OTP is not correct",
    "PLEASE_DOUBLE_CHECK_OTP": "Please double check OTP",
    "RECRUITMENT1": "Recruitment",
    "NOMINEE": "Nominee*",
    "NOMINEE2": "Nominee...",
    "PLEASE_ENTER_THE_POSITION_YOU_ARE_APPLYING_FOR": "Please enter the position you are applying for",
    "THE_DAY_YOU_CAN_GO_TO_WORK": "The day you can go to work*",
    "PLEASE_SELECT_THE_DATE_YOU_CAN_GO_TO_WORK": "Please select the date you can go to work",
    "DATE_OF_GOING_TO_WORK_MUST_BE_AFTER_THE_CURRENT_DATE": "Date of going to work must be after the current date",
    "YOUR_CV": "Link your CV...",
    "PLEASE_ENTER_YOUR_CV_LINK": "Please enter your CV ",
    "NOT_HELPFUL": "Not helpful",
    "NORMAL": "Normal",
    "VERY_HELPFUL": "Very helpful",
    "IMPORT_CONTENT": "Import content...",
    "THIS_ARTICLE": "This article",
    "IS_USEFUL": "is useful",
    "TO_YOU": "to you?",
    "YOUR_REVIEW_WILL_HELP_US_IMPROVE_THE_QUALITY_OF_FUTURE_ARTICLES": "Your review will help us improve the quality of future articles",
    "PRESS_COOPERATION": "Press / Cooperation",
    "APPLICATION_SUPPORT": "Application support",
    "SERVICE_REFLECTION": "Service Reflection",
    "COMMUNICATION_COLLABORATION": "Communication/Collaboration",
    "ORTHER": "Orther",
    "THE_SERVER_IS_HAVING_PROBLEMS": "The server is having problems",
    "SORRY_WE_ARE_EXPERIENCING_SOME_TECHNICAL": "Sorry, we are experiencing some technical issues affecting your registration processing. We are working to fix this problem",
    "SORRY_WE_ARE_EXPERIENCING_SOME_TECHNICAL2": "Sorry, we are experiencing some technical issues that are affecting the processing of your information. We are working to fix this issue",
    "THE_OTP_YOU_ENTERED_IS_NOT_CORRECT": "The OTP you entered is not correct",
    "PLEASE_DOUBLE_CHECK_THE_RECEIVED_OTP": "Please double check the received OTP",
    "THERE_WAS_A_PROBLEM_MAKING_THE_REQUEST": "There was a problem making the request",
    "SORRY_WE_WILL_FIX_THE_PROBLEM_AS_SOON_AS_POSSIBLE_WISH_YOU_SYMPATHIZE": "Sorry, we will fix the problem as soon as possible. Wish you sympathize.",
    "DISCOVER_OTHER_PRODUCTS": "Discover other products",
    "NOTIFICATION": "Notification",
    "INSURANCE_PERIOD_FROM": "Insurance period from",
    "TO": "to",
    "YOUR_POLICY_WILL_EXPIRE_IN_THE_NEXT": "Your policy will expire in the next",
    "PLEASE_ENSURE_YOUR_RENEWAL": ", please ensure your renewal is done on time to maintain the validity of your policy.",
    "OVERDATE_CONTRACT": "Overdate contract",
    "YOUR_CONTRACT_HAS_EXPIRED_FOR_RENEWAL": "Your contract has expired for renewal, to continue using the insurance package, please buy a new product.",
    "NEW_PURCHASE": "New purchase",
    "EXPIRATION_DATE": "Expiration date",
    "INSURANCE_MONEY": "Insurance money",
    "INSURANCE_PERIOD": "Insurance period",
    "PLEASE_FILL_IN_THE_REQUIRED_INFORMATION": "Please fill in the required information below so that the system can check correctly for you",
    "CHECK": "Check",
    "CONTRACT_INFORMATION": "contract information",
    "REQUEST": "Request",
    "RECEIVED": "received",
    "IZION24_CONSULTING_TEAM_WILL_CALL_YOU_BACK": "IZIon24's consulting team will call you back as soon as possible! Watch out for the call with the caller information IZIon24",
    "ROUTER_PROMOTION": "/en/news/promotion",
    "ROUTER_PRIVARY_POLICY": "/en/page/privacy-policy",
    "ABOUT_US": "About us",
    "PLACEHOLDER_SEARCH": "Find the right question",
    "WHERE_ARE_YOU_FROM": "Where are you from...",
    "PAYMENT": "Payments",
    "TYPE_DATEPICKER": "mm/dd/yyyy",
    "SELECT_DATEPICKER": "Select",
    "CANCEL_DATEPICKER": "Cancel",
    "PREMIUM_DES": "Sum insured * 1.65% (including 10% VAT)",
    "SUM_INSURE_DES": "According to motorbike value calculation table",
    "FIELD_ADDRESS": "Field address",
    "PRIVACY_POLICY_DETAILS": "Privacy policy details",
    "ENTER_URL": "Enter url",
    "TEXT_SITUATION": "Situation",
    "APPLY_FILTER": "Apply",
    "TEXT_SEARCH_FILTER": "Choose the nature of insurance",
    "INTERESTED_PRODUCT": "INTERESTED PRODUCT",
    "CLOSE": "Close",
    "CLICK_TO_UPLOAD": "Click to upload",
    "YOUR_CV1": " your CV",
    "PLEASE_ENTER_THE_CORRECT_CV_LINK": "Please enter the correct CV link",
    "SELECTED_PRODUCT": "Selected",
    "UNSELECTED_PRODUCT": "Clear",
    "SELECTION_PRODUCT_LIST": "All options ",
    "CHOOSE_ALL_PRODUCT": "Select all",
    "USE_YOUR_ACCOUNT_REGISTRATION_INFORMATION": "Use signed-up information to log into IZIon24 app now!​",
    "SIGN_UP": "Sign up successful! ​​",
    "SUCCESSFUL": "successful!",
    "DOWNLOAD_IZION24​": "DOWNLOAD IZION24​",
    "INSURNACE_PRODUCT_INFO": "Insurance products on IZIon24",
    "PLEASE_ENTER_A_MESSAGE": "Please enter a message",
    "SENDING_REQUEST_FAILED": "Sending request failed",
    "ALREADY_INSURANCE": "Already have insurance?",
    "NOT_SURE_WHERE": "Not sure where to start?",
    "ROUTER_HOME": "/en",
    "EMAIL_IS_ALREADY_IN_USE1": "Email is already in use",
    "TERM_AND_CONDITION": "Term and condition",
    "EXCLUSION_CLAUSE": "Exclusion clause",
    "SHARE_LINK": "Link copied"
}
