<template>
    <NuxtLayout>
        <NuxtPage> </NuxtPage>
    </NuxtLayout>
    <!-- <div
        class="hidden bg-live-primary bg-live-primary sm:w-1/2 md:w-9/12 lg:w-1/2 shadow-md md:flex-row mx-5 sm:m-0 rounded"
    ></div> -->
</template>

<script lang="ts" setup>
import AOS from 'aos'
import 'aos/dist/aos.css'

const route = useRoute()
useHead({
    htmlAttrs: {
        lang: (route.params.lang || 'vi').toString()
    }
})
onMounted(() => {
    window.onscroll = () => {
        if (window.scrollY == 0) {
            document.querySelectorAll('.aos-animate').forEach((item) => {
                item.classList.remove('aos-animate')
            })
            AOS.refresh()
        }
    }
})
</script>
<style>
@media (min-width: 300px) {
    [data-aos^='fade'][data-aos^='fade'],
    [data-aos^='zoom'][data-aos^='zoom'] {
        opacity: 1 !important;
        transform: translate(0) scale(1) !important;
    }
}
</style>
