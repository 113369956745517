// @ts-ignore

import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin((nuxtApp: any) => {
  if (process.client) {
    const script = document.createElement('script')
    script.src = `https://site-analytics.izion24.com.vn/tracker.js`
    script.async = true
    script.setAttribute('data-ackee-server', 'https://site-analytics.izion24.com.vn')
    script.setAttribute('data-ackee-domain-id', '6efabcc1-6ebd-4689-af6c-ad30aea17a19')
    document.head.appendChild(script)
  }
})