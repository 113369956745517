import { consola } from 'consola'

export default defineNuxtRouteMiddleware((to: any, from) => {
    //const allows = ["vi", "en", "cn"];

    // const nuxtApp = useNuxtApp()
    // const { $globalSettingsNuxt} = nuxtApp
    // let globalSetting:any = $globalSettingsNuxt

    let globalSetting: any = inject('globalSettings')

    let languages = globalSetting?.languages

    const allows = languages.map((item: any) => item.code.split('-')[0])
    const languageDefault = languages.find((item: any) => item.default)

    to.meta.LangDefault = languageDefault

    const defaultLang = languageDefault.code.split('-')[0] || 'vi'
    if (!to.params.lang || !to.params.langCode) {
        to.params.lang = defaultLang
        to.params.defaultLang = defaultLang
        to.params.langCode = languageDefault.code

        const parse = to.path.split('/')
        const langPrefix = parse[1] ?? ''

        if (allows.indexOf(langPrefix) >= 0) {
            to.params.lang = langPrefix
            to.params.langCode = languages.find((item) => item.code.split('-')[0] === langPrefix)?.code

            to.meta.lang = langPrefix
            to.meta.langCode = to.params.langCode
        }
    }

    //consola.warn(to)
})
