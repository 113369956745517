export const configRouters = [
  // { name: 'about', file: 'about', path: '/ve-chung-toi', alias: ['/en/about'] },
  {name: 'analysis', file: 'analysis', path: '/phan-tich', alias: ['/en/analysis']},
  {
    name: 'analysis_slug',
    file: 'analysis/[slug]-[id]',
    path: '/phan-tich/:slug',
    alias: ['/en/analysis/:slug']
  },
  // { name: 'contact', file: 'contact', path: '/lien-he', alias: ['/en/contact'] },
  {name: 'faq', file: 'faq', path: '/cau-hoi-thuong-gap/', alias: ['/en/faqs']},
  {
    name: 'faq_category',
    file: 'faq/[slug]-[id]',
    path: '/cau-hoi-thuong-gap/:slug-:id(\\d+)',
    alias: ['/en/faq/:slug-:id(\\d+)']
  },
  {
    name: 'faq_detail',
    file: 'faq/[slug]/[id]',
    path: '/cau-hoi-thuong-gap/:slug/:id(\\d+)',
    alias: ['/en/faq/:slug/:id(\\d+)']
  },
  // { name: 'home', file: 'home', path: '/', alias: ['/en/'] },
  // { name: 'mobile_app', file: 'mobile-app', path: '/ung-dung-dien-thoai', alias: ['/en/mobile-app'] },
  {name: 'news', file: 'news', path: '/tin-tuc', alias: ['/en/news']},
  {
    name: 'news_category',
    file: 'news/[slug]',
    path: '/tin-tuc/:slug',
    alias: ['/en/news/:slug']
  },
  {
    name: 'news_detail',
    file: 'news/[slug-category]/[slug]',
    path: '/tin-tuc/:category/:slug',
    alias: ['/en/news/:category/:slug']
  },
  // { name: 'policy', file: 'policy', path: '/chinh-sach', alias: ['/en/policy'] },
  {
    name: 'product_detail',
    file: '/products/[slug]',
    path: '/san-pham/:slug',
    alias: ['/en/products/:slug']
  },
  {name: 'products', file: 'products', path: '/san-pham', alias: ['/en/products']}

  // { name: 'result', file: 'search', path: '/tim-kiem', alias: ['/en/search'] },
  // { name: 'term_and_condition', file: 'dynamic-page', path: '/trang/:slug', alias: ['/en/page/:slug'] },
  // { name: 'privacy_policy', file: 'dynamic-page', path: '/trang/:slug', alias: ['/en/page/:slug'] }
]
